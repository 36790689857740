.m_notification{
    position: fixed;
    padding: 60px 0 0 0;
    height: 100vh;
    background-color: var(--pink);
    width: 100%;
    .m_notification_header{
        color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px 15px;
        .MuiOutlinedInput-root {
            border-radius: 50px !important;
            box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.75);
            margin: 10px 0;
        }
        #myprofile-form-field {
            background-color: var(--pinkDark);
            border-radius: 50px;
            font-family: var(--font);
        
        }
        .m_notification_head_bac_color{
            background-color: var(--pinkDark);
            width: 100vw;
            text-align: center;
            padding: 10px 0px;
        }
        
    }
    .m_f{
        font-family: var(--font);
        color:var(--white);
    }
.m_notification_contant{
    height: 50vh;
    overflow: scroll;
    padding: 0px 5px 20px 5px;
    .m_notification_contant_text_box{
   margin: 10px 0px;

    }
}
}