
.rr{
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    .MuiOutlinedInput-input{
        -webkit-text-fill-color: var(--white) !important;
        font-family: var(--font);
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium{
        color: var(--pinkDark) !important;
        font-family: var(--font) !important;

}
.selectfield{
 
    .selectcode{
      color: var(--white);
      font-family: var(--font);
      
    }
    
    .MuiSelect-select{
      text-overflow: unset !important;
    
    }
    .MuiSelect-icon{
      display: none;
    }}
    
    .menucountry{
     width: 100%;
    
    }
    #countryname{
      width: 80%!important;
        white-space: break-spaces !important;
        line-height: 1rem;
        margin-left: 10px;
    }
    .MuiStack-root{
        position: absolute;
        z-index: 1;
        top: 15%;
        left: 2%;
        right: 2%;
        background-color: var(--white);
      }
      .MuiDateCalendar-root{
        width: 100%;
      }
      .calhead{
        background-color: var(--pinkDark);
        padding: 20px;
        font-family: var(--font);
      }
}
  .MuiPickersLayout-root .MuiPickersLayout-toolbar
    {
    background-color: var(--pinkDark);
    color: var(--white) !important;
    font-family: var(--font) !important;
    .MuiTypography-root.MuiTypography-overline{
        color: var(--white) !important;
        font-family: var(--font) !important;

    }
    }
   
    .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin{
                        background-color: var(--pinkDark) !important;
    }
   
    
  
  
