.latest-content1 {
  #lokmsg {
    font-size: 10px;
    margin-top: 0px !important;
    padding-left: 0px;
  }
  p {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    text-align: start;
    padding-left: 15px;
  }
  .rec-carousel {
    display: flex;
    justify-content: start;
    // padding-left: calc(2% - 3px);
    background-color: var(--pinkDark);

    .rec-carousel-item-latest-content {
      height: 170px !important;
      display: flex;
      align-items: center;
      // padding-right: 5px;
      max-width: 100%;
      .jZpChr {
        display: flex;
        height: 90%;
        align-items: center;
        max-width: calc(100%);
        width: 100%;
        // padding-left: 13px;
        .events {
          display: flex !important;
          height: 100%;
          overflow: scroll;
          align-items: center;
          max-width: inherit;
          // .items0{
          //   margin-left: calc(2% + 10px);
          // }
          .items:first-child {
            margin-left: calc(15px) !important;
          }
          .items {
            display: flex !important;
            height: calc(100% - 13px);
            margin-left: 7px;
            margin-right: 5px;

            overflow: scroll;
            align-items: center;
            min-width: 26%;
            position: relative;
            // border: 5px solid var(--pink);
            box-shadow: 0px 0px 2px 1px var(--black);
            border-radius: 15px;
            display: flex;
            justify-content: center;
            background-color: var(--black);
            .strip-text {
              align-items: center;
              color: var(--white);
              z-index: 2;
              position: absolute;
              top: 10px;
              font-size: 8px !important;
              display: flex;
              left: 0px;
              padding: 2px 5px;
              background-image: var(--lock);
              border-radius: 0 10px 10px 0;
              border-end-end-radius: 10x;
            }
            .video-play-icon {
              position: absolute;
              height: 100%;
              width: 100%;
              justify-content: center;
              display: flex;
              align-items: center;
            }
            .lockedImageIcon {
              position: absolute;
              z-index: 2;
              height: inherit;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            img {
              overflow: scroll;
              height: auto;
              max-height: 100%;
              max-width: inherit;
              width: auto;
            }
          }
        }
      }
    }
  }
}
