.m_live {
  background-color: var(--pink);
  height: 100vh;
  position: fixed;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  //   .live_btn {
  //     padding: 10px 30px 0px 30px;
  .m_live_btn {
    background: linear-gradient(
      to right,
      var(--themeCustomColor),
      var(--orange)
    );
    border-radius: 10px;
    box-shadow: 0px 0px 7px 0.1px var(--black);
    margin: 5px 0px 5px 0px;
    height: 6vh;
    width: 100%;
    .live_f {
      font-size: 30px;
      margin-right: 4px;
    }
  }
}
// }
.live_input {
  padding: 30px;
  padding-bottom: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .MuiOutlinedInput-root {
    border-radius: 50px !important;
    margin: 10px 0;
  }
  #myprofile-form-field {
    background-color: var(--pink);
    color: white;
    border-radius: 50px;
    font-family: ImportedComoBold;
    box-shadow: 0px 0px 3px 0.1px var(--black);
  }
  .popup_feild {
    padding: 0px 30px;
  }
  .popup_dialog {
    height: 41%;
    align-items: center;
    justify-content: center;
  }
  .m_l_btn {
    background-color: var(--pink);
    color: white;
    border-radius: 10px;
    font-family: ImportedComoBold;
    width: 100px;
    box-shadow: 0px 0px 3px 0.1px var(--black);
    border: 1px solid var(--white);
    margin-top: 10px;
  }
  .m_l_btn:active {
    background-color: var(--pink);
  }
  .m_l_btn:hover {
    background-color: var(--pink);
  }
}
// .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
//     margin: 0px !important;
//     width: 100vw !important;
//     top: 35%;
//     bottom: 0px;
// }
