.m_tabbox {
    position: fixed;
    padding: 60px 0 0 0;
    background-color: var(--pink);
    width: calc(100vh - 162.33px);
    padding-bottom: 30px;
    height: -webkit-fill-available;
    .m_tabpanel {

      width: 100vw !important;
      height:100%;
     display: flex;
     justify-content: center;
     background-color: var(--pink);
     overflow-x: hidden;
     overflow-y: scroll;
    }
    .tab {
      height: 100px;
      width: 96vw;
      padding: 0px 10px;
      background-color: var(--pink);
      display: flex;
      align-items: center;
      justify-content: center;
      .tab-menu {
        display: flex;
        justify-content: center;

        .MuiTabs-scroller {
          position: fixed;
          width: max-content;
          
          background-color: var(--pink);
          border-radius: 10px;
          display: flex !important;
          justify-content: center;

          .MuiTabs-flexContainer {
            height: 61px;
            width: 100vw;
            display: flex;
            justify-content: center;
            padding: 0px !important;
            margin: 0px !important;
  
            .MuiTab-root {
              min-width: auto !important;
              width: 30.33%;
              margin: 0px 1px;
              color: white;
              font-family: var(--font);
              text-shadow: 0px 0px 0px;
              text-transform: none;
            }
            button {
              width: fit-content;
              border: none !important;
              padding: 0px !important;
            }
            .MuiTab-wrapper {
              width: fit-content !important;
            }
            div {
              img {
                width: 25px;
                aspect-ratio: 1/1;
              }
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                color: var(--white);
              }
            }
            .Mui-selected {
              background-color: var(--yellow);
              border-radius: 10px;
              font-family: var(--font);
              color: var(--white);
              text-align: center;
              div {
                padding-top: 4px;
                padding-left: 0px;
                padding-right: 0px;
              }
            }
          }
        }
      }
    }
  }

  .m_model_sh_cop_box{
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    background-color: var(--pinkDark);
    box-shadow: 0px 0px 7px 0.1px var(--black);
    padding: 15px 10px;
    overflow: inherit;
    .m_model_sh_cop_btn{
        height: 25px !important;
        margin: 10px 0px 0 0;
        border-radius: 15px !important;
        width: fit-content;
        text-transform: none;
    }
    .reject{
        border-radius: 50px !important;
        background-color: var(--pitch) !important;
    }
  }
  .m_model_sh_cop_box1{
    width: 90vw;
    margin: 0px 0px 20px 0px;
    box-shadow: 0px 0px 7px 0.1px var(--black);
    padding: 15px 10px;
    background-color: var(--pinkDark);
    .reject{
        border-radius: 50px !important;
        background-color: var(--pitch) !important;
    }
  }
  .css-19kzrtu{
    padding: 0px !important;
  }
