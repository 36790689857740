.mymsgmain {
  background-color: var(--pinkDark);
  height: 100%;
  min-height: calc(100vh - 62.33px);
  padding: 61.33px 0 0 0;
  .mymsg_head {
    background-color: var(--pinkDark);
    position: fixed;
    width: 100%;
    height: fit-content;
  }
  .mymsgtext {
    font-family: var(--font);
    color: var(--yellow);
    text-align: center;
    padding: 10px 0;
  }
  .tab {
    height: 70px;
    width: 100%;
    padding: 0 4% 0 0;
    background-color: var(--pinkDark);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    .tab-menu {
      border-radius: 15px;
      display: flex;
      justify-content: center;
      width: 90%;
      .MuiTabs-scroller {
        position: relative;
        width: 90%;

        background-color: var(--pinkDark);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        box-shadow: 0px 0px 7px 0.1px var(--black);
        .MuiTabs-flexContainer {
          min-width: 100% !important;
          width: 100%;
          padding: 0px !important;
          margin: 0px !important;
          display: flex;
          justify-content: space-between;
          background-color: var(--themeCustomColor);

          .MuiTab-root {
            width: 32% !important;
            margin: 0px 1px;
            padding: 0px 10px !important;
            color: var(--white);
            font-family: var(--font);
            text-shadow: 0px 0px 0px;
            text-transform: none;
          }
          button {
            width: fit-content;
            border: none !important;
            padding: 0px !important;
          }
          .MuiTab-wrapper {
            width: fit-content !important;
          }
          div {
            img {
              width: 25px;
              aspect-ratio: 1/1;
            }
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              color: var(--white);
            }
          }
          .Mui-selected {
            background-color: var(--white);
            border-radius: 10px;
            font-family: var(--font);
            font-weight: 500;
            color: var(--black);
            div {
              padding-left: 0px;
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
  .tabpanel {
    min-height: calc(100vh - 194px) !important;
    width: 100vw;
    padding: 131.33px 0 0 0;
    overflow: scroll;
    display: flex;
    justify-content: center;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .a {
      padding: 0px !important;
    }
  }
}
.complet_text_box {
  background-color: var(--pinkDark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 15px 0;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 1px var(--black);
  width: 85vw;
  padding: 5px 5%;
  // padding-left: 0px;
  margin-left: -25px !important;
  .video_icon_box {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--orange);
    border-radius: 50%;
  }
  .t {
    font-size: 13px;
    font-family: var(--font);
    text-shadow: 2px 1px 0.1px var(--black);
  }
}
.Rejected_text_box {
  background-color: var(--themeCustomColor);
  text-align: left;
  margin: 15px 0;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 1px var(--black);
  width: 85vw;
  padding: 5px 5%;

  margin-left: -25px !important;

  .t {
    font-size: 13px;
    font-family: var(--font);
    text-shadow: 2px 1px 0.1px var(--black);
  }
}
