.smsgmain {
  background-color: var(--pink);
  height: 100%;
  min-height: 100vh;
  .text {
    color: var(--white);
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
  }
  .sheading {
    position: absolute;
    padding-left: 10px;
  }
  .msgcontent {
    padding: 20px 5%;
    .textarea {
      background-color: var(--themeCustomColor);
      color: var(--white);
      width: -webkit-fill-available;
      border-radius: 10px;
      padding: 10px;
      border: none;
      margin: 20px 0px;
      box-shadow: 0px 0px 7px 1px var(--black);
    }
    .textarea::placeholder {
      color: var(--white);
    }
    .chekgroup {
      display: flex;
      .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-size: 12px;
      }
      .MuiSvgIcon-root {
        color: var(--white) !important;
      }
      .MuiCheckbox-colorPrimary.Mui-checked {
        .MuiSvgIcon-root {
          color: var(--pinkDark) !important;
          fill: var(--white) !important;
        }
      }
    }
    .shoutoutbtn {
      background-color: var(--themeCustomColor);
      border-radius: 50px;
      font-family: var(--font);
      margin: 15px 0;
      text-transform: capitalize;
      padding: 10px;
      box-shadow: 0px 0px 7px 0.5px var(--black);
      font-size: small;
    }
  }
}
