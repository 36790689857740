@font-face {
    font-family: ImportedComoRegular;

    src: url(../../Como/Como.ttf);
}

@font-face {
    font-family: ImportedComoMedium;
    src: url(../../Como/Como-Medium/Como-Medium.ttf);
}

@font-face {
    font-family: var(--font);
    src: url(../../Como/Como-Bold/Como-Bold.ttf);
}
.headermain{
    position: fixed;
    z-index: 500;
    background-color: var(--pink);width: 100%;
}
.headerbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
   

}



.adminLogo {
    height: 40px;
}

#videobtn {
    background-color: var(--pitch);
    border-radius: 15px;
    padding: 2px 20px;
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
   display: flex;
   align-items: center;
   box-shadow: 0px 0px 7px 2px rgba(0,0,0,0.75);
}

.menubtn {
    background-color: var(--themeCustomColor);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
}

#videobtn:active,
.menubtn:active {
    transform: translateY(4px);
}