.login-container {
  height: 100dvh;
  background-size: cover;
  background-position-x: center;
  position: sticky;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .passlable {
    color: var(--white);
    font-family: var(--font);
  }
  .passinput {
    color: var(--white);
    font-family: var(--font);
  }
  img {
    width: 50%;
    margin-bottom: 13px;
  }
  .MuiTabs-root {
    margin-bottom: 20px;

    #simple-tab-0 {
      margin-right: 20px;
      font-size: 16px;
      outline-offset: -3px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--white);
      color: var(--white);
    }

    #simple-tab-1 {
      margin-left: 20px;
      font-size: 16px;
      outline-offset: -3px;
      border: 2px solid var(--white);
      color: var(--white);
    }
  }
  .MuiTextField-root {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.typography-loginpage {
  text-shadow: 2px 2px 4px var(--black);
}

.MuiTabs-scroller {
  .MuiTabs-indicator {
    display: none;
  }
}

.MuiTypography-body1 {
  font-family: var(--font) !important;
  color: var(--white);
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  min-height: 100vh !important;
  min-width: 90vw;
}
.selectfield {
  .selectcode {
    color: var(--white);
    font-family: var(--font);
  }

  .MuiSelect-select {
    text-overflow: unset !important;
  }
  .MuiSelect-icon {
    display: none;
  }
}

.menucountry {
  width: 100%;
}
#countryname {
  width: 80% !important;
  white-space: break-spaces !important;
  line-height: 1rem;
  margin-left: 10px;
}
