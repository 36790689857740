.walletmaindiv {
  padding: 260px 0 2vh 0;
  height: 100%;
  min-height: 72vh;
  background-color: var(--black);
  .walletdivbalance {
    position: fixed;
    top: 133.66px;
    z-index: 10;
    padding: 7% 0 5% 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
  }
  .walletgiftbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5% 20px;
    padding: 10px 10px;
    background-color: var(--themeCustomColor);
    border-radius: 15px;
    box-shadow: 0px 0px 7px 1px var(--black);
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
      color: var(--white) !important;
      background-color: var(--blueLight) !important;
    }
    .giftbox {
      height: 50px;
      aspect-ratio: 1/1;
      background-color: #57456b;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      min-width: 50px !important;
      .img {
        height: 60px !important;
        border-radius: 26px;
        max-width: 60px !important;
      }
    }

    .contentbox {
      margin-left: 15px;
      width: 100%;
      .date {
        font-size: 11px;
      }
    }
  }
}

.recharge-gems-style {
  position: fixed;
  top: 217px;
  width: 100vw;
  background-color: var(--black);
}
