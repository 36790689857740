.srv {
  background-color: var(--pinkDark);
  padding: 61.33px 0 0 0;
  min-height: calc(100vh - 61.33px);
  height: 100%;
  .typography-srv {
    font-family: var(--font);
    color: var(--white);
    text-shadow: 2px 1px 1px var(--black);
    text-align: center;
    padding: 10px 0;
  }

  .srv2 {
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .typ-chekgroupsrv {
      display: flex;
      align-items: center;
      font-family: var(--font);
      color: var(--white);
    }
    .MuiStack-root {
      position: absolute;
      z-index: 1;
      top: 15%;
      left: 2%;
      right: 2%;
      background-color: var(--white);
    }
    .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium {
      color: var(--pinkDark) !important;
      font-family: var(--font) !important;
    }
    .MuiSvgIcon-root {
      color: var(--pinkDark) !important;
    }
    .MuiDateCalendar-root {
      width: 100%;
    }
    .calhead {
      background-color: var(--pinkDark);
      padding: 20px;
      font-family: var(--font);
    }
    .MuiPickersLayout-root .MuiPickersLayout-toolbar {
      background-color: var(--pinkDark);
      color: var(--white) !important;
      font-family: var(--font) !important;
      .MuiTypography-root.MuiTypography-overline {
        color: var(--white) !important;
        font-family: var(--font) !important;
      }
    }

    .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin {
      background-color: var(--pinkDark) !important;
    }
    .srvbtn {
      background-color: var(--themeCustomColor) !important;
      width: fit-content;
      font-family: var(--font);
      text-shadow: 2px 1px 1px var(--black);
    }
    .chekgroupsrv {
      display: flex;
      align-items: center;
      border: 10px solid var(--gray);
      margin: 15px 0px;
      padding: 5px;
      text-align: left;
      min-width: 80vw;
      justify-content: space-between;
      background-color: var(--grayborder);
      border-radius: 10px;
      .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-size: 12px;
      }

      .MuiSvgIcon-root {
        color: var(--pinkDark) !important;
        padding-left: 20px;
      }
      .MuiCheckbox-colorPrimary.Mui-checked {
        .MuiSvgIcon-root {
          color: var(--pinkDark) !important;
        }
      }
    }
    .srv_text {
      .MuiOutlinedInput-root {
        width: 90vw !important;
        padding: 5px !important;
        background-color: var(--themeCustomeColor);
        border-radius: 15px;
        color: var(--white);
        margin: 10px 0px;
      }
    }
  }
  .MuiOutlinedInput-input {
    background-color: var(--themeCustomColor);
    height: 8px;
    border-radius: 0px;
    color: var(--white);
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
    border-radius: 5px;
    box-shadow: none;
    padding: 10px;
  }
}
.css-1hbyad5-MuiTypography-root {
  display: none !important;
}
.css-umzx0k-MuiClock-pin {
  background-color: var(--pinkDark) !important;
}
.css-d0vs79-MuiClockPointer-root {
  background-color: var(--pinkDark) !important;
}
.css-eg3pzz-MuiClockPointer-thumb {
  background-color: var(--pinkDark) !important;
  border: 16px solid var(--pinkDark) !important;
}
.css-rdq5h4-MuiClockPointer-root {
  background-color: var(--pinkDark) !important;
}
.css-12t0dn4-MuiClockPointer-thumb {
  background-color: var(--pinkDark) !important;
  border: 16px solid var(--pinkDark) !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: var(--pinkDark) !important;
  font-family: var(--font) !important;
}
.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root {
  color: var(--white) !important;
}
.css-1f43ekz-MuiTypography-root-MuiPickersToolbarText-root-MuiTimePickerToolbar-separator {
  color: var(--white) !important;
}
.css-ihhycx-MuiTimePickerToolbar-amPmSelection .MuiTimePickerToolbar-ampmLabel {
  color: var(--white) !important;
}
.cmline {
  height: 2px;
  background-color: white;
  border-top: 1px solid var(--pinkDark);
  border-bottom: 1px solid var(--black);
}
