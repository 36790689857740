.m_gift{
    background-color: var(--pink) ;
    min-height: 100vh;
    height: 100%;
    padding: 60px 0 20px 0;
    position: fixed;
width: 100%;
    .m_gift_header{
        line-height: 1rem;
        text-align: center;
        width: 100%;
        height: 50px;
        padding: 5px 0px;
    .m_gift_head{
        color: var(--yellow);
        text-shadow: 2px 1px 1px black  ;

    }
    .m_gift_head2{
        color: var(--white);
        text-shadow: 2px 1px 1px black  ;
        margin-bottom: 5px;
    }}
    .m_gift_contant{
        overflow: scroll;
        height: calc(100vh - 25vh);
        // height: 500px;
        margin-top: 5px;
        color: var(--white);
        text-align: center;
        padding: 0px 10px;
        padding-bottom: 30px;
        .m_c_box{
            padding: 10px;
            margin: 10px 0px;
            .m_gift_text{
                line-height: 1rem;
            }
        }
    }
}