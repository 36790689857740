.spop {
  background-color: var(--pinkDark);
  .stext{
    font-family: var(--font);
    color: var(--black);
    margin: 10px;
  }
  .sappbar {
    position: fixed;
    bottom: 0;
    z-index: 20;
    bottom: 0 !important;
    padding: 0px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    .sappbarbtn{
      width: 100%;
      background-color: var(--white);
    font-family: var(--font);
    padding: 15px 0px;
    text-shadow: 2px 1px 1px var(--black);
  font-size: 18px;
  text-transform: capitalize;
    }
  }
}

