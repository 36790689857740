.video-call-main-page{
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100vh;
    overflow: hidden;
    .video-call-screen{
    height: 100px;
    display: flex;
    justify-content: space-around;
    width: 90vw;
    align-items: center;
    position: fixed;
    bottom: 8vh;
    z-index: 2;
    .call-icon{
       color: var(--white);
       padding: 10px;
       border-radius: 50%;
    }
    .call-icon:hover {
        background-color: var(--pitch);
      }
      .call-icon:active {
        background-color: var(--pitch);
        transform: translateY(4px);
      }
}

.call-image{
    height: 100vh;
    background-position: cover;
    
}

}

.is-modal{
    width:40vw;
    height: 40vh;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 200;
}

.not-is-modal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 10;
}