@font-face {
    font-family: ImportedComoRegular;
    src: url(../Como/Como.ttf);
}

@font-face {
    font-family: ImportedComoMedium;
    src: url(../Como/Como-Medium/Como-Medium.ttf);
}

@font-face {
    font-family: var(--font);
    src: url(../Como/Como-Bold/Como-Bold.ttf);
}


.upmain {
    background-color: var(--pink);
    height: 100%;
    min-height: 100vh;
    padding: 62px 0px 0px 0px;
    width: 100vw; 
    overflow-x:  hidden;
}

#updiv {
    padding: 10px;
    text-align: center;
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
    color: var(--yellow);
}

#line {
    height: 1px;
    background-color: var(--white);
    border-bottom: 1px solid var(--black);
    border-top: 1px solid var(--pinkDark);
}

#btn {
    background-color: var(--pinkDark);
    font-family: var(--font);
    box-shadow: 0px 0px 3px 1px var(--black);
    margin: 0px 10px;
    border-radius: 40px;
    height: 35px;

}

.btndiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.formdiv {
    padding: 15px 0px;
}

#formheadig {
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
    color: var(--white);
    padding: 0px 15px;
}

.formimgdiv {
    height: auto;
    max-width: 100%;
    padding: 10px 15px;
    background-color: var(--pinkDark);
}

#formimgheadig {
    font-family: var(--font);
    color: var(--white);
}

.img {
    height: 200px;
    width: 100%;
    border-radius: 15px;


}

.boximg {
    border: 2px solid var(--pink);
    border-radius: 18px;
    height: 200px;
    width: 100%;
}

#formtext {
    color: var(--white);
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
    float: right;
}

.box {
    display: flex;
    align-items: center;
    padding: 20px 20px 0 10px;
}

.formimg {
    height: 30px;
    width: 30px;
    float: right;

}



#publishbtn {
    background-color: var(--pinkDark);
    border-radius: 40px;
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
    padding: 10px 25px;
    margin-top: 20px;
    margin-bottom: 80px;
    font-size: 0.7rem;
    box-shadow: 0px 0px 3px 1px var(--black);
    text-transform: none;
}

#publishbtn:active,
#btn:active {
    transform: translateY(4px);
}
.upinput{
    #textfild {
        background-color: var(--pinkDark);
        color: var(--white);
        border-radius: 50px;
        font-family: var(--font);
        padding: 12px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
        padding: 0px;
       
    }
    
    .MuiOutlinedInput-root {
        border-radius: 50px !important;
        box-shadow: 0px 0px 3px 0.1px var(--black);
        padding: 0px !important;
    }

}
.upinput2{
    #textfild {
        background-color: var(--pinkDark);
        color: var(--white);
        border-radius: 15px;
        font-family: var(--font);
        padding: 12px;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
        padding: 0px;
       
    }
    
    .MuiOutlinedInput-root {
        border-radius: 15px !important;
        box-shadow: 0px 0px 3px 0.1px var(--black);
    }

}