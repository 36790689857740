.m_ug {
  padding: 70px 5% 0 5%;
  background-color: var(--pink);
  height: 100vh;
  width: 90%;

  position: fixed;
  .AddDminputbox {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    
    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
      border-radius: 50px !important;
      border: 2px solid var(--white);
      box-shadow: 0px 0px 7px 1px black;
    }
    .searchbtnbox {
      width: 55px;
      height: 45px;
      margin-left: 10px;
      border-radius: 50%;
      background-color: var(--blueLight);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 7px 1px black;
    }
  }
  .or {
    font-family: ImportedComoBold;
    text-shadow: 2px 1px 1px black;
  }
  .search_result_box {
    padding: 5px 0 0 0;

    .search_result_boxa {
      background-color: var(--pinkDark);
      box-shadow: 0px 0px 7px 1px black;
      text-align: center;
      padding: 10px 0px;
      margin: 10px 0px;
      border-radius: 10px;
    }
  }
}
.m_ug_b {
  background-color: var(--pink);
  height: 100dvh;

  .m_ug_b_head {
    display: grid;
    grid-template-columns: 30% 50%;
    align-items: center;
    padding: 15px 0px 15px 15px;
    .menubtn {
      background-color: #232323;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      padding-top: 0px;
      box-shadow: 0px 0px 7px 1px var(--black);
    }
  }
  .m_f {
    font-size: 20px;
  }
  .m_ug_tab {
    padding: 30px 20px;

    .tab-menu {
      display: flex;
      justify-content: center;
      width: 100%;
      border-radius: 5px;
      overflow: revert;
      .MuiTabs-scroller {
        position: relative;
        width: 90%;

        background-color: var(--pinkDark);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        box-shadow: 0px 0px 7px 0.1px black;
        .MuiTabs-flexContainer {
          min-width: 100% !important;
          width: 100%;
          padding: 0px !important;
          margin: 0px !important;
          display: grid;
          // justify-content: space-between;
          grid-template-columns: auto auto;
          .MuiTab-root {
            width: 100% !important;
            margin: 0px 1px;
            padding: 0px 8px !important;
            color: white;
            font-family: var(--font);
            text-shadow: 0px 0px 0px;
            text-transform: none;
          }
          button {
            width: fit-content;
            border: none !important;
            padding: 0px !important;
          }
          .MuiTab-wrapper {
            width: fit-content !important;
          }
          div {
            img {
              width: 25px;
              aspect-ratio: 1/1;
            }
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              color: var(--white);
            }
          }
          .Mui-selected {
            max-width: -webkit-fill-available;

            background-color: var(--yellow);
            border-radius: 10px;
            font-family: var(--font);
            font-weight: 500;
            color: var(--black);
            div {
              padding-left: 0px;
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
  .tabpanels {
    padding: 30px 0px;
    height: 100% !important;
    .m_ug_yellow {
      color: var(--yellow);
      line-height: 1.5rem;
      font-size: 20px;
    }
    .b_update {
      display: grid;
      grid-template-columns: 30% 70%;
      align-items: center;
      margin: 10px 0px;
      .grp_bt {
        height: 40px;
        background-color: var(--pinkDark);
        width: 90%;
        padding: 1px;
        box-shadow: 0px 0px 4px 0.1px var(--black);
        border-radius: 10px;
        .yellow {
          background-color: var(--yellow) !important;
          min-width: 50% !important;
          border-radius: 10px;
          border: none;
          font-size: 26px;
        }
        .not_yellow {
          min-width: 50% !important;
          border: none;
          font-size: 26px;

        }
        .notyellow {
          background-color: var(--yellow) !important;
          border-radius: 5px;
          border: none;
        }
      }

      #myprofile-form-field {
        background-color: var(--pink);
        border: 2px solid var(--white);
        border-radius: 50px;
        font-family: var(--font);
        height: 40px !important;
        padding: 3px 10px !important;
        box-shadow: 0px 0px 4px 0.1px var(--black);
      }
    }
    .up_b {
      height: 48px;
      padding-left: 20px !important;
      padding-right: 20px !important;
      background-color: var(--pinkDark);
      border-radius: 24px;
      text-transform: none;
      box-shadow: 0px 0px 4px 0.1px var(--black);
      text-shadow: 2px 1px 1px var(--black);
      font-family: var(--font);
    }
    .a {
      padding: 0px !important;
    }
  }
}
