@font-face {
  font-family: ImportedComoRegular;
  src: url(../../component/Como/Como.ttf);
}

@font-face {
  font-family: var(--font);
  src: url(../../component/Como/Como-Medium/Como-Medium.ttf);
}

@font-face {
  font-family: var(--font);
  src: url(../../component/Como/Como-Bold/Como-Bold.ttf);
}

.headingdiv {
  position: fixed;
  z-index: 499;
  background-color: var(--black);
  top: 50px;
  padding: 10px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid var(--white);
}

#heading {
  color: var(--yellow);
  font-family: var(--font);
  text-shadow: 2px 1px 1px var(--black);
}

.photomain {
  background-color: var(--pinkDark);
  height: 100%;
  min-height: 100vh;
  padding: 45px 0 0 0;
  width: 100vw;
}
.photomain_modal {
  background-color: var(--pinkDark);
  height: 100%;
  min-height: 100vh;
  // padding: 45px 0 0 0;
  width: 100vw;
}
.profiletext {
  width: calc(100% - 65px);
}
.photodiv {
  padding: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .photodiv_imgdiv {
    width: 50px;
  }
}
.profilepic {
  width: 50px;
  height: 50px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 2px solid var(--white);
  overflow: hidden;
}
.photoimg {
  height: 60px;
}

#photousername {
  color: var(--white);
  font-family: var(--font);
  text-shadow: 2px 1px 1px var(--black);
  line-height: 1rem;
}

#photocaption {
  color: var(--white);
  font-family: var(--font);
  text-shadow: 2px 1px 1px var(--black);
  line-height: 1.5rem;
  white-space: nowrap;
  width: 100%;
  overflow: auto;
}

.postimg {
  width: 100%;
  height: 100%;
  border-radius: 17px;
}

.postimgdiv {
  margin-bottom: 8px;
  padding: 0% 5% 0% 5%;
  position: relative;
}

.postdiv {
  width: 100%;
  height: 100%;
  border: 2px solid var(--black);
  border-radius: 20px;
  box-shadow: 0px 0px 7px 1px var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.photolikediv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2px;
  .MuiIconButton-root {
    padding: 0px 0px 5px 0px;
  }
}

.imlock {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  top: 20px;
  padding: 7px 20px 7px 35px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-image: linear-gradient(to right, var(--orange), var(--pitch));
  color: var(--white);
  text-shadow: 2px 1px 1px var(--black);
  font-family: var(--font);
}
.photoLock {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  top: 20px;
  padding: 7px 20px 7px 35px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-image: linear-gradient(to right, var(--orange), var(--pitch));
  color: var(--white);
  text-shadow: 2px 1px 1px var(--black);
  font-family: var(--font);
  left: 5%;
}

.lockcontant {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  top: 45%;
  width: calc(90% + 4px);
  font-family: var(--font);
  text-shadow: 2px 2px 1px var(--black);
  font-size: 18px;
  color: var(--white);
}

#lockimg {
  filter: blur(50px);
}

.photoendline {
  background-color: var(--black);
  height: 7px;
}
#btnlikecm {
  font-size: 30px;
  text-decoration: none;
  color: var(--white);
  padding-right: 7px;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}
#btnlikecm:active {
  transform: translateY(4px);
}

.photopopup {
  /* Model or popup */
  .MuiDialog-paper {
    background-color: var(--pinkDark) !important;
    width: 300px;
    padding: 0 10px;
    border: 2px solid var(--white);
    border-radius: 15px;
    display: flex;
    justify-content: center;
  }
  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    text-align: center;
  }
  .css-qfso29-MuiTypography-root-MuiDialogContentText-root {
    display: flex;
  }
  .jemsimg {
    height: 20px;
    width: 25px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: var(--white);
  }
  #modeltext {
    color: var(--white);
    font-family: var(--font);
    text-shadow: 2px 0.1px 1px var(--black);
    margin-right: 8px;
    text-align: center;
  }
  .modelbtndiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    #popbtn {
      text-transform: none;
      background-color: var(--pink);
      width: 210px;
      margin: 0 0 20px 0px;
      box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.75);
      font-family: var(--font);
      text-shadow: 2px 1px 1px var(--black);
      border-radius: 10px;
    }
  }

  #context {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    margin-bottom: 0px !important;
    .context-div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px !important;
    }
  }
  .css-uhb5lp {
    background-color: var(--pinkDark) !important;
  }
}
