.hh {
  .header {
    position: fixed;
    z-index: 500;
    background-color: var(--black);
    width: 100%;
  }
  .headbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    width: 100%;
  }
  .MuiSvgIcon-root {
    color: var(--white);
  }
  #jemstext {
    text-align: center;
    color: var(--white);
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
    font-size: 12px;
    line-height: 0.1cm;
    padding: 0 5%;
  }

  .adminLogo {
    height: 45px;
    width: 100%;
  }
  .jemsimg {
    height: 30px;
    width: 35px;
    margin-bottom: 5px;
  }

  #videobtn {
    background-color: var(--themeCustomColor);
    border-radius: 15px;
    padding: 2px 10px;
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 7px 2px var(--black);
  }

  .menubtn {
    background-color: var(--themeCustomColor);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    padding-top: 1px;
  }
}
