.tabbox {
  padding: 115.66px 0 0 0;
  background-color: var(--black);
  min-height: 85vh;
  height: 100%;
  .tabpanel {
    min-height: 60vh;
    width: 100%;
    min-height: -webkit-fill-available !important;
    padding: 15vh 0 0 0;
    display: flex;
    justify-content: center;
  }
  .tab {
    height: 15vh;
    width: 96vw;
    padding: 0px 0% 0 2vw;
    background-color: var(--black);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    .tab-menu {
      display: flex;
      justify-content: center;

      .MuiTabs-scroller {
        position: fixed;
        width: max-content;

        background-color: var(--pinkDark);
        border-radius: 10px;
        display: flex !important;
        justify-content: center;
        box-shadow: 0px 0px 7px 0.1px var(--black);
        .MuiTabs-flexContainer {
          background-color: var(--themeCustomColor);
          height: 55px;
          min-width: fit-content !important;
          width: 85vw;
          display: flex;
          justify-content: space-between;
          padding: 0px !important;
          margin: 0px !important;

          .MuiTab-root {
            min-width: auto !important;
            margin: 0px 1px;
            padding: 0px 10% !important;
            color: var(--white);
            font-family: var(--font);
            text-shadow: 0px 0px 0px;
            text-transform: none;
          }
          button {
            width: fit-content;
            border: none !important;
            padding: 0px !important;
          }
          .MuiTab-wrapper {
            width: fit-content !important;
          }
          div {
            img {
              width: 25px;
              aspect-ratio: 1/1;
            }
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              color: var(--white);
            }
          }
          .Mui-selected {
            background-color: var(--yellow);
            border-radius: 10px;
            font-family: var(--font);
            color: var(--black);
            min-width: 28vw !important;
            div {
              padding-left: 0px;
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
}
.today {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  padding: 0px;
  .crown {
    height: 30px;
    width: 70px;
    margin: 10px 0;
  }
  .placeimgbox {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    background-color: var(--pinkDark);
    display: flex;
    justify-content: center;
    align-items: center;
    .placeimg {
      height: 70px;
      width: 70px;
      border: 1px solid var(--black);
      border-radius: 50%;
    }
  }
  .je {
    display: flex;
    align-items: center;
  }
}

.month {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  text-align: center;
  min-height: -webkit-fill-available !important;

  .win {
    min-width: 94vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;

    .no1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
      width: 33%;
      .crown1 {
        height: 30px;
        width: 60px;
        margin: 10px 0;
      }
      .placeimgbox1 {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background-color: var(--themeCustomColor);
        display: flex;
        justify-content: center;
        align-items: center;
        .placeimg1 {
          height: 80px;
          width: 80px;
          border: 1px solid var(--black);
          border-radius: 50%;
        }
      }
    }

    .no2 {
      width: 33%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      margin-top: 10px;
      padding: 0px 10px;
      .crown2 {
        height: 30px;
        width: 50px;
        margin: 10px 0;
      }
      .placeimgbox2 {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        background-color: var(--themeCustomColor);
        display: flex;
        justify-content: center;
        align-items: center;
        .placeimg2 {
          height: 50px;
          width: 50px;
          border: 1px solid var(--black);
          border-radius: 50%;
        }
      }
    }
    .no3 {
      width: 33%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      margin-top: 35px;
      padding: 0px 10px;

      .crown3 {
        height: 25px;
        width: 40px;
        margin: 10px 0;
      }
      .placeimgbox3 {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background-color: var(--themeCustomColor);
        display: flex;
        justify-content: center;
        align-items: center;
        .placeimg3 {
          height: 40px;
          width: 40px;
          border: 1px solid var(--black);
          border-radius: 50%;
        }
      }
    }
  }
  #list {
    .list_box {
      display: flex;
      align-items: center;
      background-color: var(--themeCustomColor);
      margin: 15px 0px;
      padding: 5px 10px 5px 10px;
      height: 50px;
      border-radius: 10px;
      box-shadow: 0px 0px 7px 0.1px var(--black);
      .superfan-profile-pic {
        margin: 0px 10px;
        height: 40px;
        min-width: 40px !important;
        aspect-ratio: 1/1;
        border-radius: 50%;
        border: 1px solid var(--white);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }
      .listimg {
        min-width: 50px !important;
      }
    }
  }
  .je {
    display: flex;
    align-items: center;
  }
}
