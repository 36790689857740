.forgotpassword-container {
  height: 100dvh;
  background-size: cover;
  background-position-x: center;
  position: sticky;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  img {
    width: 50%;
    margin-bottom: 25px;
  }
  .textfield {
    margin-bottom: 10px;
    width: 90%;
  }
  .forgotpassword-page-button {
    margin-bottom: 25px;
    width: 90%;
    border-radius: 28px;
    height: 56px;
    background-color: var(--pink);
    text-transform: none;
    font-size: 20px;
  }
  .forgotpassword-page-button:hover {
    background-color: var(--pink);
  }

  p{
    margin-bottom: 25px;
    font-family: var(--font);
  }
}

