.audience-side-live {
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 30px;
    height: 45px;
    background-color: var(--trnaparentBlack);
  }
  .css-1v4ccyo{
    border-radius: 25px !important;
  }
}

.chatBox{
  padding:5px;
  background-color: transparent;
  max-height: 200px;
  width: 100vw;
  background-color: var(--trnaparentPink);
  overflow: scroll;
}

 .chatmessages:first-child{
    margin-top: 10px !important;
  }

  .font-size{
    font-size: 10px !important;
  }

  .chat-font-size{
    font-size: 15px !important;
  }

  .show-button{
    bottom: 10px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-icon-box{
    width: 50px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 1px solid var(--white);
    z-index:1000;
     position:fixed;
     bottom:10px;
     display: flex;
     justify-content: center;
     align-items: center;
  }