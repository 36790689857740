.MuiDrawer-paper {
  width: -webkit-fill-available !important;

  .drawer-menu {
    background-color: var(--black);
    .menuitem {
      padding: 8px;
      .listitem {
        text-shadow: 2px 1px 1px var(--black);
        font-family: var(--font) !important;
        .MuiTypography-root {
          font-family: var(--font) !important;
        }
      }
    }
    .close {
      border-radius: 50%;
      padding: 5px;
      width: 8% !important;
      box-shadow: 0px 0px 7px 1px var(--black);
    }

    .close:hover {
      background-color: var(--pink);
    }
    .close:active {
      transform: translateY(4px);
    }
    .app-version {
      margin-bottom: 20px;
      text-align: center;
    }
    .MuiBox-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-top: 20px;
        margin-bottom: 30px;
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .MuiList-root {
      padding-left: 5%;
      padding-right: 5%;
      .MuiListItem-gutters {
        margin-bottom: 20px;
        background-image: var(--menubutton);
        border-radius: 10px;
        border: 1px solid var(--white);
        box-shadow: 0px 0px 7px 1px var(--black);
        img {
          width: 10%;
          padding-right: 10px;
          padding-left: 10px;
          border-right: 2px solid var(--white) !important;
        }
        button {
          text-transform: none;
        }
      }
      .MuiListItem-gutters:hover {
        background-color: var(--pink);
      }
      .MuiListItem-gutters:active {
        transform: translateY(4px);
      }
    }
  }
}
.loguotpop {
  .MuiDialog-container {
    .MuiDialogTitle-root {
      color: var(--white);
      font-size: 22px;
      font-family: var(--font);
      text-align: center;
    }
    .MuiDialog-paperWidthSm {
      background-color: var(--pinkDark) !important;
      width: 100%;
      border: 3px solid var(--white);
      border-radius: 30px;
      margin: 15px;
    }
    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
      padding: 0px 10px;
      p {
        padding-top: 10px;
        padding-bottom: 30px;
        color: var(--white);
        font-size: 18px;
        text-align: center;
        font-family: var(--font);
      }
      button {
        width: 65%;
        color: var(--white) !important;
        background-color: var(--themeCustomColor);
        border-radius: 10px;
        border: 1px solid var(--black);
        height: 45px;
        font-size: 1rem;
        box-shadow: 0px 0px 7px 0.1px var(--black);
        text-transform: none;
        font-family: var(--font);
        margin-bottom: 15px;
      }
    }
    .MuiDivider-root {
      background-color: var(--white);
      height: 2px;
      margin-left: 5%;
      margin-right: 5%;
    }
  }
}
@media only screen and (max-width: 352px) {
  .loguotpop {
    .MuiDialog-container {
      .MuiDialogContent-root {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
        padding: 0px 10px;
        p {
          padding-top: 10px;
          padding-bottom: 30px;
          color: var(--white);
          font-size: 15px;
          font-family: var(--font);
        }
      }
    }
  }
}
