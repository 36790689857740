
.popup{.MuiDialog-container{
    .dialog-title-background{
        color:var(--white) !important;
        text-align: center;
        font-family: var(--font);
    }
    .MuiDialog-paperWidthSm{
        background-color: var(--pinkDark) !important;
        width: 100%;
        border: 3px solid var(--white);
        border-radius: 30px;
    }
    .MuiDialogContent-root{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
        p{
            padding-top: 10px;
            text-align: center;
            padding-bottom: 10px;
            color:var(--white);
            font-size: 1.20rem;
            font-family: var(--font);
        }
        button{
            width:90%;
            color:var(--white) !important;
            background-color: var(--pink);
            border-radius: 10px;
            border: 1px solid var(--black);
            height:45px;
            font-size: 1rem;
            box-shadow: 0px 0px 2px 0.1px var(--black);
            text-transform: none;
            font-family: var(--font);
        }
        button:active{
            background-color: var(--pink);
            transform: translateY(4px);        }

    }
    .MuiDivider-root{
        background-color: var(--white);
        height:2px;
        margin-left: 5%;
        margin-right: 5%;
    }

}}


