.videocall {
  padding: 60px 0 0 0;
  background-color: var(--pink);
  min-height: calc(100vh - 61.33px);
  .shoutOutButton {
    margin-top: 30px;
    width: calc(96% - 20px);

    button {
      background-image: var(--buttonColor);
      color: var(--white) !important;
      text-transform: none;
      margin-left: calc(10px + 2%);
      height: 50px;
      width: 100%;
      border-radius: 15px;
      font-size: 20px;
      text-shadow: 2px 2px 4px var(--black);
      font-family: var(--font);
      box-shadow: 0px 0px 7px 1px var(--black);
    }
    button:hover {
      background-color: var(--pitch);
    }
    button:active {
      background-color: var(--pitch);
      transform: translateY(4px);
    }
  }
  .volumebox {
    height: 40px;
    width: 40px;
    background-color: var(--pink);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 80px;
    right: 20px;
    box-shadow: 0px 0px 7px 0.1px var(--black);
  }
  .videoheading {
    font-family: var(--font);
    color: var(--yellow);
    text-shadow: 2px 1px 1px var(--black);
    text-align: center;
    padding: 10px 0;
  }
  .tab {
    height: 85px;
    width: 100%;
    padding: 0 0% 0 0;
    background-color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    .tab-menu {
      display: flex;
      justify-content: center;
      width: 90%;
      overflow: inherit;
      .MuiTabs-scroller {
        position: relative;
        width: 90%;

        background-color: var(--pinkDark);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        box-shadow: 0px 0px 7px 0.1px var(--black);
        .MuiTabs-flexContainer {
          min-width: 100% !important;
          background-color: var(--themeCustomColor);
          width: 100%;
          padding: 0px !important;
          margin: 0px !important;
          display: flex;
          justify-content: space-between;

          .MuiTab-root {
            width: 32% !important;
            margin: 0px 1px;
            padding: 0px 10px !important;
            color: var(--white);
            font-family: var(--font);
            text-shadow: 0px 0px 0px;
            text-transform: none;
          }
          button {
            width: fit-content;
            border: none !important;
            padding: 0px !important;
          }
          .MuiTab-wrapper {
            width: fit-content !important;
          }
          div {
            img {
              width: 25px;
              aspect-ratio: 1/1;
            }
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              color: var(--white);
            }
          }
          .Mui-selected {
            background-color: var(--yellow);
            border-radius: 10px;
            font-family: var(--font);
            font-weight: 500;
            color: var(--black);
            div {
              padding-left: 0px;
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
  .ll {
    min-height: max-content !important;
  }
  .tabpanel {
    width: 100vw;
    padding: 0 0 0 0;
    display: flex;
    justify-content: center;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .a {
      padding: 0px 15px !important;
      width: 100%;
    }
  }
}

.bookbtn {
  background-color: var(--themeCustomColor) !important;
  padding: 0px 30px 0px 10px !important;
  height: 40px !important;
  font-size: 11px !important;
  font-family: var(--font);
  text-shadow: 2px 1px 1px var(--black);
  width: 100% !important;
  margin: 10px 0px !important;
  box-shadow: 0px 0px 7px 0.1px var(--black) !important;
}

.cmdbox {
  background-color: var(--themeCustomColor);
  margin: 15px 0px;
  border-radius: 10px;
  padding: 8px 15px;
  box-shadow: 0px 0px 7px 0.1px var(--black);
  .cpmmsg {
    font-size: 13px !important;
    text-shadow: 2px 1px 1px var(--black);
  }
}

.button-text-align-start {
  display: flex !important;
  justify-content: flex-start !important;
  text-transform: none !important;
  font-size: 15px !important;
  margin-bottom: 20px !important;
  padding: 10px 10px !important;
  font-family: var(--font) !important;
}
