.adddm {
  background-color: var(--pink);
  height: 100%;
  min-height: 100vh;
  .AddDMmain {
    background-color: var(--pink);
    padding: 25% 5% 0 5%;
    position: fixed;
    width: 90%;
    text-align: center;
    .AddDminputbox {
      display: flex;
      align-items: center;
      .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
        border-radius: 50px !important;
      }
      .searchbtnbox {
       aspect-ratio: 1/1;
        width: 67px;
        margin-left: 10px;
        border-radius: 50%;
        background-color: var(--blueLight);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 7px 1px var(--black);
      }
    }
    #myprofile-form-field {
      background-color: var(--themeCustomColor);
      color: var(--white);
      border-radius: 50px;
      font-family: var(--font);
      border: 1px solid var(--white);
      padding: 15px;
        }
   
  }
  .or {
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
  }
  .search_result_box {
    padding: 35vh 5% 5% 5%;
    
    .search_result_boxa {
      background-color: var(--pinkDark);
      box-shadow: 0px 0px 7px 1px var(--black);
      text-align: center;
      padding: 10px 0px;
      margin: 10px 0px;
    }
  }
}
