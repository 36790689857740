.video-call-main-page{
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100vh;
    overflow: hidden;
    .video-call-screen{
    display: flex;
    justify-content: space-between;
    width: 60vw;
    align-items: center;
    position: fixed;
    bottom: 8vh;
    z-index: 2;
    .call-icon{
       color: var(--white);
       padding: 10px;
       border-radius: 50%;
    }
    .call-icon:hover {
        background-color: var(--pitch);
      }
      .call-icon:active {
        background-color: var(--pitch);
        transform: translateY(4px);
      }
}

.call-image{
    height: 100vh;
    background-position: cover;
    
}

}