.groupbutton {
  padding: 20px 0px;
  display: flex;
  justify-content: center;

  .buttond {
    border: none;
    border-radius: 20px;
    background-color: var(--pinkDark);
    color: var(--white);
    padding: 10px 35px;
    text-transform: none;
  }
}

.mypurchmaindiv {
  background-color: var(--pinkDark);
  padding-top: 28vh;
}

#fan {
  color: #000000;
  font-family: var(--font);
  background-color: var(--white);
}
.porder {
  background-color: var(--pink) !important;
  display: flex;

  justify-content: center;
}
.orderm {
  background-color: var(--pinkDark);
  padding: 10px 10px;
  display: flex;
  width: 90%;
  border-radius: 15px;
}
.descrption {
  margin-left: 10px;
}
.typo {
  font-family: var(--font) !important;
}

.tabboxnMypurchasePage {
  padding: 15vh 0 0 0;
  background-color: var(--black);
  min-height: calc(100vh - 61px) !important;
  height: 100%;
  .tabpanel {
    min-height: -webkit-fill-available !important;
    width: 100%;
    height: 100%;
    padding: 15vh 0 0 0;
    display: flex;
    justify-content: center;
  }
  .tab {
    height: 15vh;
    width: 96vw;
    background-color: var(--black);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    .tab-menu {
      display: flex;
      justify-content: center;

      .MuiTabs-scroller {
        position: fixed;
        width: max-content;

        background-color: var(--pinkDark);
        border-radius: 10px;
        display: flex !important;
        justify-content: center;
        box-shadow: 0px 0px 7px 0.1px var(--black);
        .MuiTabs-flexContainer {
          min-width: fit-content !important;
          width: 85vw;
          display: flex;
          justify-content: space-between;
          padding: 0px !important;
          margin: 0px !important;
          background-color: var(--themeCustomColor);

          .MuiTab-root {
            min-width: 28vw !important;
            width: 28vw !important;
            margin: 0px 1px;
            padding: 0px 10% !important;
            color: var(--white);
            font-family: var(--font);
            text-shadow: 0px 0px 0px;
            text-transform: none;
          }
          button {
            width: fit-content;
            border: none !important;
            padding: 0px !important;
          }
          .MuiTab-wrapper {
            width: fit-content !important;
          }
          div {
            img {
              width: 25px;
              aspect-ratio: 1/1;
            }
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              color: var(--white);
            }
          }
          .Mui-selected {
            background-color: var(--white);
            border-radius: 10px;
            font-family: var(--font);
            color: var(--black);
            div {
              padding-left: 0px;
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
}

.tabpanel {
  min-height: 100vh !important;
  .css-19kzrtu {
    padding: 0px !important;
  }
}

#myprofileimg {
  height: 50px;
  width: 50px;
}

.mypvrline {
  height: 40px;
  width: 2px;
  background-color: var(--white);
  margin: 0 15px;
}
.myPurchasePageHeader {
  // position: fixed;
  z-index: 499;
  background-color: var(--pink);
  top: 61.33px;
  padding: 63px 0 0 0;
  width: 100%;
}

.MyprofileHeadingdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px 0;
}

.mypline {
  height: 1px;
  background-color: var(--white);
  border-bottom: 1px solid var(--black);
  border-top: 1px solid var(--pinkDark);
}
