.home_feed_head {
  display: flex;
  justify-content: space-around;
  padding-bottom: 8px;
  padding-top: 15px;
  .home_feed_img {
    height: 60px;
    width: 60px;
  }
}

.home_feed {
  background-color: var(--pinkDark);
  height: calc(110vh - 333.5px);
  overflow: scroll;
  position: fixed;
  bottom: 61px;
  width: 100vw;
  // padding: 10px 10px 50px 10px;
}
.feed {
  color: var(--yellow) !important;
}
//Grid_view page

.Grid_view {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px 10px 10px;
  .img_box {
    min-height: 100px;
    height: 85%;
    width: 90%;
    border-radius: 7px;
    border: 4px solid var(--pink);
    position: relative;
    box-shadow: 0px 0px 7px 0.1px var(--black);

    .delete_icon {
      position: absolute;
      z-index: 1;
      font-size: x-large;
      right: 0px;
      color: red !important;
      opacity: 0.8;
    }

    .grid_img {
      height: 100%;
      width: 100%;
      border-radius: 4px;
    }
  }

  .video_box {
    min-height: 140px;
    height: 85%;
    width: 90%;
    border-radius: 7px;
    border: 4px solid var(--pink);
    position: relative;
    box-shadow: 0px 0px 7px 0.1px var(--black);

    .delete_icon {
      position: absolute;
      z-index: 1;
      font-size: x-large;
      right: 0px;
      color: red !important;
      opacity: 0.8;
    }

    .grid_img {
      height: 100%;
      width: 100%;
      border-radius: 4px;
    }
  }

  .paid_text {
    position: absolute;
    z-index: 1;
    top: 5px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    background-image: linear-gradient(to right, var(--orange), var(--pitch));
    width: 83%;
    font-size: 10px;
    padding: 0px 0px 0px 2px;

    display: flex;
    height: 3em;
    justify-content: flex-start;
    align-items: center;
  }
  .img_text_box {
    height: 20%;
    position: absolute;
    z-index: 1;
    bottom: 0px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.493);
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-around;
    .text {
      display: flex;
      align-items: center;
      font-size: small;
      width: 17%;
    }
    .img_text {
      font-size: small;
      margin: 0px 4px;
      filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
      font-size: 1.28em;
      height: 1em;
    }
    .video_text {
      font-size: small;
      margin: 0px 4px;
      filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
      font-size: 1.28em;
      height: 1em;
    }
  }
  .img_text_span {
    font-size: 0.8em;
    height: 2.5em;
    display: flex;
    align-items: center;
    color: var(--white);
    font-family: var(--font);
    // padding-top: 0.2em;
  }

  .video_text_span {
    font-size: 0.8em;
    height: 1.5em;
    display: flex;
    align-items: center;
    color: var(--white);
    // padding-top: 0.2em;
  }
  .video_box {
    height: 90px;
    width: 90%;
    border-radius: 7px;
    border: 4px solid var(--pink);
    position: relative;
    margin: 5px 0px;
    box-shadow: 0px 0px 7px 0.1px var(--black);

    .grid_video {
      height: 100%;
      width: 100%;
      border-radius: 4px;
      background-size: contain;
    }
    .video_text_box {
      position: absolute;
      z-index: 1;
      bottom: 0px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.493);
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      justify-content: space-around;
    }
  }
}
//Video Page CSS :-
.videolockcontant {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  top: 26%;
  width: 90%;
  font-family: var(--font);
  text-shadow: 2px 2px 1px var(--black);
  font-size: 18px;
  color: var(--white);
  left: 5%;
  margin-top: 12px;
}
.videolockcontant_video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  top: 30%;
  width: 90%;
  font-family: var(--font);
  text-shadow: 2px 2px 1px var(--black);
  font-size: 18px;
  color: var(--white);
}
#playimg {
  height: 50px;
  width: 50px;
}

// Profile_View

.profile_view {
  padding: 10px 0px 10px 0px;
  .photo_contant {
    padding: 10px 10px;
  }
  .photodiv {
    padding: 0px;
    display: flex;
    align-items: center;

    .photodiv_imgdiv {
      width: 60px;
    }
  }
  #photousername {
    color: white;
    font-family: ImportedComoBold;
    text-shadow: 2px 1px 1px black;
    line-height: 1rem;
  }
  .paid_text {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 10px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    background-image: linear-gradient(to right, var(--orange), var(--pitch));
    width: 150px;
    padding: 0px 0px 0px 2px;

    // display: flex;
    height: 3em;
    justify-content: center;
    // align-items: center;
  }
  #photocaption {
    color: white;
    font-family: var(--font);
    text-shadow: 2px 1px 1px black;
    line-height: 1.5rem;
    white-space: nowrap;
    width: 100%;
    overflow: auto;
  }
  .img_box {
    position: relative;
    width: fit-content;
    border-radius: 11px;
    border: 4px solid var(--pink);
    margin: 10px 0px;
    box-shadow: 0px 0px 7px 0.1px var(--black);
    .post_img {
      width: 100%;
      border-radius: 7px;
    }
  }
  .img_text_box {
    display: flex;
    padding: 0px 50px;
    align-items: center;
    justify-content: space-between;
    .text {
      font-size: medium;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .img_text {
      margin: 0px 0px;
      filter: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));
    }
  }
  .photoendline {
    width: 100vw;
  }
}

.lock_text {
  font-size: 1em;
}
