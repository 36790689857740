.rechargebox {
  position: fixed;
  z-index: 499;
  background-color: var(--black);
  top: 60px;
  width: 100%;
  height: 40vh;
  padding: 0px 0px 20px 0px;
  .rechargeimg {
    height: 100%;
    width: 100%;
  }
}
.rechargeheadingbox {
  position: fixed;
  z-index: 500;
  top: 65px;
  width: 100%;
  padding: 0px 0px 20px 0px;
  text-align: center;
  #rechargeheading {
    background: linear-gradient(to right, #c100c1 25%, #ff6b08, #f32170);
    color: transparent;
    background-clip: text;
    font-family: var(--font);
  }
}
.rechargecontentbox {
  padding: 50vh 20px 0px 20px;
  background-color: var(--black);
  //   background-color: #ba06b4;
  min-height: 60vh;
  height: 100%;

  .rechargecontent {
    // background-color: #ba06b4;
    background-color: var(--pink) !important;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
    margin: 20px 0px;
  }
}
