.dm_head {
  background-color: var(--black);
  border-bottom: 2px solid var(--white);
  text-shadow: 2px 1px 1px var(--black);
  text-align: center;
  padding: 5px 8px;
  .dm_head_1 {
    color: var(--yellow);
    text-shadow: 2px 1px 1px var(--black);
    font-family: var(--font);
    line-height: 1.2rem;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .dm_head_2 {
    text-shadow: 2px 1px 1px var(--black);
    font-family: var(--font);
    line-height: 1rem;
    font-size: 0.7rem;
  }
}
.userdmpage {
  position: fixed;
  top: 60px;
  height: -webkit-fill-available;
  background-color: var(--pink);
  .MuiDialog-container {
    align-items: flex-end !important;
  }
  .dm_bottom {
    background-color: var(--themeCustomColor) !important;
    margin: 5px 3% 0 3%;
    padding: 10px 2%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 90vw;
    align-items: center;
    box-shadow: 0px 0px 3px 0.5px var(--black);
    .MuiInputBase-adornedEnd {
      display: block !important;
      background-color: var(--black) !important;
      border-radius: 17px !important;
    }
    .css-i44wyl {
      width: 62%;
      border-radius: 15px;
      box-shadow: 0px 0px 3px 0.2px var(--black);
      .css-1bn53lx {
        flex-direction: column;
      }
    }
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      width: 62%;
      border-radius: 15px;
      box-shadow: 0px 0px 3px 0.2px var(--black);
      .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        display: flex;
        flex-direction: column;
        border: none;
        justify-content: center;
        padding-right: 0px;
        border-radius: 10px;
      }
    }
    .css-1bn53lx {
      background-color: var(--pink);
      border-radius: 15px;
    }
    .css-1v4ccyo {
      border-radius: 15px;
    }
    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      border-radius: 18px !important;
      background-color: var(--pink) !important;
    }
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      background-color: var(--pink) !important;
    }
    #dm_bottm_textfild {
      border-radius: 18px;
      max-width: -webkit-fill-available;
      /* overflow: hidden; */
      background-color: var(--black);
      /* box-shadow: 0px 0px 5px 0.1px var(--black); */
      color: var(--white);
      font-family: var(--font);
    }
    #dm_bottm_textfild_userdm {
      max-width: -webkit-fill-available;
      /* overflow: hidden; */
      background-color: var(--black);
      /* box-shadow: 0px 0px 5px 0.1px var(--black); */
      color: var(--white);
      font-family: var(--font);
    }

    .dma {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: var(--pink);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 7px 0.9px var(--black);
    }
    .dmb {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      box-shadow: 0px 0px 7px 0.9px var(--black);
      background-color: var(--black);
      color: white !important;
      .dmbimg {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .dmc {
      height: 30px;
      width: 30px;
      background-color: var(--orange);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 7px 0.9px var(--black);
    }
  }
}
.dm {
  font-family: var(--font);
  height: calc(100vh - 212px);
  background-color: var(--black);
  overflow-y: scroll;
  .senddm_msgbox {
    box-shadow: 0px 0px 7px 0.9px var(--black);
    color: var(--white);
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
    margin-bottom: 10px;
    background-color: var(--themeCustomColor);
    width: 100%;
    max-width: fit-content;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow-wrap: anywhere;
  }
  .recdm_msgbox {
    box-shadow: 0px 0px 7px 0.9px var(--black);
    color: var(--white);
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
    margin-bottom: 10px;
    background-color: var(--themeCustomColor);
    width: fit-content;
    border-radius: 10px;
    padding: 10px;
    float: left;
    overflow-wrap: anywhere;
    color: var(--white) !important;
  }
  .sendmsgside {
    width: 96vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 2%;
  }
  .recivemsgside {
    width: 96vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2%;
  }
  .msgimgDM {
    height: 100%;
    width: 200px;
  }
  .hiddenVisibility.msgimgDM {
    visibility: hidden;
  }
  .gifPhoto {
    width: 200px;
    aspect-ratio: 1/1.4;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  @media only screen and (max-width: 375px) {
    #dm_bottm_textfild {
      width: 180px !important;
    }
  }
  @media only screen and (max-width: 365px) {
    #dm_bottm_textfild {
      width: 170px !important;
    }
  }
  @media only screen and (max-width: 355px) {
    #dm_bottm_textfild {
      width: 160px !important;
    }
  }
  @media only screen and (max-width: 345px) {
    #dm_bottm_textfild {
      width: 150px !important;
    }
  }
}

.dm_gifs {
  position: absolute;
  bottom: 5px;
  z-index: 10000;
}

#analystics-button {
  color: var(--white) !important;
  background-color: var(--pinkDark);
  border-radius: 18px;
  padding: 6px 15px;
  text-transform: none;
  width: -webkit-fill-available;
}

#analystics-box {
  width: "-webkit-fill-available" !important;
}
#chat-header {
  display: flex;
  justify-content: space-between;
}

.dmc {
  height: 30px;
  width: 30px;
  background-color: var(--orange);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 7px 0.9px var(--black);
}
.dma {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--blueLight);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 7px 0.9px var(--black);
}
