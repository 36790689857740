@font-face {
  font-family: ImportedComoRegular;
  src: url(../../component/Como/Como.ttf);
}

@font-face {
  font-family: var(--font);
  src: url(../../component/Como/Como-Medium/Como-Medium.ttf);
}

@font-face {
  font-family: var(--font);
  src: url(../../component/Como/Como-Bold/Como-Bold.ttf);
}

.maincommentdiv {
  max-width: 100vw !important;
  overflow-y: hidden;
  background-color: var(--pinkDark);
  min-height: -webkit-fill-available !important;
  overflow-y: scroll;
  height: auto;
  padding: 142px 0px 0px 0px;
}

.addcommentdiv {
  position: fixed;
  display: flex;
  z-index: 499;
  top: 60px;
  justify-content: center;
  align-items: center;
  background-color: var(--pinkDark);
  width: 100%;

  padding: 15px 0px;

  .MuiInputBase-adornedEnd {
    border-radius: 50px !important;
    background-color: var(--pink);
    box-shadow: 0px 0px 3px 0.1px var(--black);
    padding-right: 5px !important;
  }
  .MuiTextField-root {
    width: 90%;
  }
}

.sendbox {
  height: 30px;
  width: 30px;
  background-color: var(--blueLight);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.75);
  .MuiSvgIcon-root {
    font-size: 1rem;
  }
}

#addfield {
  height: 30px;
  border-radius: 50px;
  background-color: var(--pink);
  color: var(--white);
  font-family: var(--font);
}

.commentdivp {
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100vh;
  margin-bottom: 8px;
}

.commentdivp_cm {
  width: calc(100% - 70px);
}
.commentprofile {
  min-width: 50px !important;
  height: 50px !important;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 2px solid var(--white);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .pimg {
    height: 60px;
  }
}

#cmmsg {
  color: var(--white);
  font-family: var(--font);
  text-shadow: 2px 1px 1px var(--black);
  line-height: 1.2rem;
  width: 99%;
  // white-space: nowrap;
  max-width: calc(100vw - 100px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-comment {
  min-height: -webkit-fill-available !important;
  width: -webkit-fill-available !important;
  text-align: center;
  display: flex;
  justify-content: center;
  padding-top: 25vh;
}
