.shoutdiv {
  padding: 0vh 0 0vh 0;
  height: 100%;
  background-color: var(--pink);

  .volumebox {
    height: 40px;
    width: 40px;
    background-color: var(--pink);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 80px;
    right: 20px;
    box-shadow: 0px 0px 7px 0.1px var(--black);
  }

  .shoutoutmaindiv {
    background-color: var(--black);

    .shoutoutheading {
      color: var(--yellow);
      font-family: var(--font);
      text-shadow: 2px 1px 1px var(--black);
      text-align: center;
      padding: 10px 0;
    }
  }
  .shoutoutcontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 4%;
    .shoutoutbtn {
      background-color: var(--themeCustomColor);
      border-radius: 50px;
      font-family: var(--font);
      margin: 10px 0;
      text-transform: capitalize;
      padding: 10px;
      box-shadow: 0px 0px 7px 0.5px var(--black);
      font-size: small;
    }
    .shouttext {
      font-family: var(--font);
      text-shadow: 2px 1px 1px var(--black);
      color: var(--white);
      margin: 10px 0;
    }
    .processdiv {
      display: flex;
      align-items: center;
    }
    .shoutoutbtna {
      border: 0.01px solid var(--white);
      background-color: var(--themeCustomColor);
      border-radius: 10px;
      font-family: var(--font);
      text-transform: capitalize;
      font-size: medium;
      padding: 10px;
      text-shadow: 2px 1px 1px var(--black);
      box-shadow: 0px 0px 7px 0.5px var(--black);
      margin: 10px 0;
      padding: 10px 30px;
    }
    .prline {
      background-color: var(--white);
      width: 3px;
      height: 30px;
      margin: 0 10px;
    }
  }
}
