.m_upbanner{
    background-color: var(--pink) ;
    min-height: 100vh;
    height: 100%;
    padding: 60px 0 20px 0;
    position: fixed;
width: 100%;

    .m_upbanner_header{
        line-height: 1rem;
        text-align: center;
        width: 100%;
        padding: 5px 0px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        
    .m_upbanner_head{
        color: var(--yellow);
        text-shadow: 2px 1px 1px black  ;

    }
    
    }
    .cc{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .upbanner_btn{
        background-color: var(--pinkDark) !important;
        border-radius: 50px;
        margin: 25px 0px;
        box-shadow: 0px 0px 7px 0.1px var(--black) !important;
        font-family: var(--font);
        padding: 5px 15px;
        
    }
    .upbanner_btn:active{
        transform: translateY(4px);
        background-color: var(--pinkDark);

    }
    .no_banner{
        color: var(--white);
        background-color: var(--pinkDark);
        width: 96%;
        padding: 10px 0px 10px 30px;

    }
    .c_head{
        color: var(--white);
        font-size: 16px;
        text-align: center;
        padding: 10px 0px 5px 0px;
        font-family: var(--font);

    }
    .m_upbanner_contant{
        // overflow: scroll;
        height: calc(100vh - 280px);
        margin-top: 5px;
        color: var(--white);
        text-align: center;
        padding-bottom: 30px;
        .banner_box{
            padding: 10px 5px;
            margin: 5px 0px;
            background-color: var(--pinkDark);
            display: grid;
            grid-template-columns: 33% 33% 33%;
            max-height: 50vh;
            overflow: scroll;
            align-items: center !important;
            .banner_img_box{
                height: auto;
                width: 28vw ;
                border: 2px solid var(--pink);
                border-radius: 17px;
                margin: 10px 1.5vw;
               position: relative;
               overflow: hidden;
               display: flex;
               align-items: center;
               justify-content: center;
            }
            .banner_img{
                height: 150px;
                width: auto;
                border-radius: 15px;
            }
            .upbanner_delete{
                position: absolute;
                top: 10px;
                right: 10px;
                color: var(--red);
                opacity:0.5;
             
            }
        }

    }
}