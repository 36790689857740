
//model_call_panding

.model_call_panding{
    padding: 0px 10px;
    height: 75vh;
    overflow: scroll;
    padding-bottom: 50px;
    .typography-size{
      font-size: 18px;
    }
    .b{
        background-color: var(--pinkDark);
        margin: 15px 0px;
        text-align: center;
        box-shadow: 0px 0px 7px 0.1px var(--black);
        padding: 20px 0px;
        border-radius: 10px;
        .model_call_video_btn{
            background-color: var(--orange);
            border-radius: 50%;
           min-width: 40px;
            height: 40px;
            padding: 5px !important;
            aspect-ratio: 1/1;
            box-shadow: 0px 0px 3px 0.1px var(--black);
            font-family: var(--font);

        }
        .model_call_video_btn1{
            height: 30px;
            padding:20px 20px;
            border-radius: 10px;
            margin-left: 10px;
            box-shadow: 0px 0px 3px 0.1px var(--black);
            text-transform: none;
            font-size: 18px;
            font-family: var(--font);
            color:var(--white);
            font-family: var(--font) !important;
            text-shadow: 2px 1px 1px var(--black);
          

        }
        .btnc2{
            background-color: var(--orange);
            margin-top: 10px;
            color: var(--white) !important;

        }
    }
}
.model_call_completed{
    padding: 0px 10px;
    height: 75vh;
    overflow: scroll;
    padding-bottom: 50px;
    .b{
        margin: 20px 0px;
            box-shadow: 0px 0px 7px 0.1px var(--black);
            background-color: var(--pinkDark);
            padding: 15px 15px;
            border-radius: 15px;
       .tt{
        font-size: 18px;
       }
    }
}



//Model_Update_videocall_schedule
.videocall_schedule{
  background-color: var(--pink);
  height: 100dvh;
  position: fixed;
  width: 100%;
  padding: 60px 0 0 0;
text-align: center;
.typography-loginpage{
    color: var(--white);
    margin: 7px 0px;
    font-family: var(--font);
    font-size: 18px;
}
.videocall_schedule_contant{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

.typ-chekgroupsrv {
    display: flex;
    align-items: center;
    font-family: var(--font);
    color: white;
  }
  .MuiStack-root {
    position: absolute;
    z-index: 1;
    top: 15%;
    left: 2%;
    right: 2%;
    background-color: white;
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium {
    color: var(--pinkDark) !important;
    font-family: var(--font) !important;
  }
  .MuiSvgIcon-root {
    color: var(--pinkDark) !important;
  }
  .MuiDateCalendar-root {
    width: 100%;
  }
  .calhead {
    background-color: var(--pinkDark);
    padding: 20px;
    font-family: var(--font);
  }
  .MuiPickersLayout-root .MuiPickersLayout-toolbar {
    background-color: var(--pinkDark);
    color: white !important;
    font-family: var(--font) !important;
    .MuiTypography-root.MuiTypography-overline {
      color: white !important;
      font-family: var(--font) !important;
    }
  }

  .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin {
    background-color: var(--pinkDark) !important;
  }
  .srvbtn {
    background-color: var(--pinkDark) !important;
    width: fit-content;
    font-family: var(--font);
    text-shadow: 2px 1px 1px black;
    height: 50px;
    box-shadow: 0px 0px 10px 0.1px var(--black);
    padding: 10px 20px !important;
  }
  .chekgroupsrv {
    display: flex;
    align-items: center;
    border: 10px solid grey;
    margin: 15px 0px;
    padding: 5px;
    text-align: left;

    .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
      font-size: 12px;
    }
    
    .MuiSvgIcon-root {
      color: var(--pinkDark) !important;
      padding-left: 20px;
    }
    .MuiCheckbox-colorPrimary.Mui-checked {
      .MuiSvgIcon-root {
        color: var(--pinkDark) !important;
      }
    }
  }
  .srv_text{
      width: 100%;
  .MuiOutlinedInput-root{
      width: 300px !important;
      padding: 5px !important;
      background-color: var(--themeCustomColor);
      border-radius: 15px;
      color: white;
      margin: 10px 0px;
  }}

.MuiOutlinedInput-input{
  background-color: var(--pinkDark);
  height: 8px;
  border-radius: 0px;
  color: white;
  font-family: var(--font);
 text-shadow: 2px 1px 1px black;
 box-shadow: none;
}}
}
.css-1hbyad5-MuiTypography-root {
  
  display: none !important;
}
.css-umzx0k-MuiClock-pin{
  background-color: var(--pinkDark) !important;
}
.css-d0vs79-MuiClockPointer-root{
  background-color: var(--pinkDark) !important;

}
.css-eg3pzz-MuiClockPointer-thumb{
  background-color: var(--pinkDark) !important;
 border: 16px solid var(--pinkDark) !important;
}
.css-rdq5h4-MuiClockPointer-root{
  background-color: var(--pinkDark) !important;

}
.css-12t0dn4-MuiClockPointer-thumb{
  background-color: var(--pinkDark) !important;
  border: 16px solid var(--pinkDark) !important;

}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  color: var(--pinkDark) !important;
  font-family: var(--font) !important;
}
.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root{
  color: white !important;
}
.css-1f43ekz-MuiTypography-root-MuiPickersToolbarText-root-MuiTimePickerToolbar-separator{
  color: white !important;
}
.css-ihhycx-MuiTimePickerToolbar-amPmSelection .MuiTimePickerToolbar-ampmLabel {

  color: white !important;
}