.volumebox {
    height: 40px;
    width: 40px;
    background-color: var(--pink);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 80px;
    right: 20px;
    box-shadow: 0px 0px 7px 0.1px var(--black);
  }
  .livebtnbox{
    position: absolute;
    top: 65vh;
    left: 25%;
    .livebtn{
        background-color: var(--pink);
        padding: 10px 60px;
        border-radius: 10px;
        text-transform: none;
        font-family: var(--font);
        box-shadow: 0px 0px 7px 0.1px var(--black);
    }
    .livebtn:hover{
      background-color: var(--pink);
    }
  }