/* @media screen and (max-height:634px) {
    .op{
        height: 634px;
    }
}
@media screen and (max-height:734px) {
    .op{
        height: 715px;
    }
}
@media screen and (max-height:710px) {
    .op{
        height: 709px;
    }
}

@media screen and (max-height:810px) {
    .op{
        height: 809px;
    }
}
@media screen and (max-height:910px) {
    .op{
        height: 909px;
    }
}
@media screen and (max-height:1010px) {
    .op{
        height: 1009px;
    }
} */
.mainop{
    height: 100vh;
}
.op {
    background-color: var(--black);
    display: flex;
    align-items: center;
    min-height: 100vh;
    height: 100%;

}

.opdiv {
    padding: 10px 0px 10px 0px;
    
}

.opimg {
    width: 100vw;
    /* min-height: 400px; */
    /* max-height: 450px; */
    height: 100%;
    object-fit: contain;
    position: absolute;
    /* top: 15%; */
}

.lcBox {
    padding: 5px 0px;
    width: 100%;
    position: absolute;
    /* z-index: 2; */
    bottom: 10px;
}

.backicon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-left: 10px;
    color: var(--white);
    position: relative;
    top: 20px;

}

.btn {
    display: flex;
    justify-content: space-evenly;
}

.likecomment {
    height: 30px;
}

.likecomment:active {
    transform: translateY(4px);
}