@font-face {
  font-family: ImportedComoRegular;
  src: url(../../component/Como/Como.ttf);
}

@font-face {
  font-family: ImportedComoMedium;
  src: url(../../component/Como/Como-Medium/Como-Medium.ttf);
}

@font-face {
  font-family: var(--font);
  src: url(../../component/Como/Como-Bold/Como-Bold.ttf);
}
.css-tzrvf0 {
  padding: 0px;
}
.userhelpHeadingMain {
  position: fixed;
  z-index: 499;
  background-color: var(--black);
  top: 60px;
  padding: 10px 0 0 0;
  width: 100%;
}

#userhelpimg {
  height: 50px;
  width: 50px;
}

.userhelpvrline {
  height: 40px;
  width: 2px;
  background-color: var(--white);
  margin: 0 15px;
}

.userhelpHeadingdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px 0;
}

.userhelpline {
  height: 1px;
  background-color: var(--white);
  border-bottom: 1px solid var(--black);
  border-top: 1px solid var(--pinkDark);
}

#userhelpheading {
  font-family: var(--font);
  text-shadow: 2px 1px 1px var(--black);
  color: var(--yellow);
}

.userhelpmaindiv {
  background-color: var(--black);
  height: 100%;
  min-height: 78vh;
  padding: 22vh 20px 0 20px;
}

.helpandsupporttextfieldbbackground {
  background-color: var(--black);
}

.userhelpinput {
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-multiline.css-z1o969-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 0px;
  }

  .MuiOutlinedInput-root {
    border-radius: 50px !important;
    box-shadow: 0px 0px 10px 0.5px rgb(0 0 0 / 75%);
    margin: 10px 0;
  }

  #userhelp-form-field {
    background-color: var(--themeCustomColor);
    color: var(--white);
    border-radius: 50px;
    font-family: var(--font);
    padding: 12px;
  }
}

.userhelpinputa {
  #userhelp-form-field-text {
    background-color: var(--themeCustomColor);
    color: var(--white);
    border-radius: 15px;
    font-family: var(--font);
    padding: 12px;
  }

  .MuiOutlinedInput-root {
    border-radius: 15px !important;
    box-shadow: 0px 0px 10px 0.5px rgb(0 0 0 / 75%);
    margin: 10px 0;
    padding: 0px;
  }
}

.userhelpbottombox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 0;

  #userhelp {
    background-color: var(--themeCustomColor);
    color: var(--white);
    font-family: var(--font);
    box-shadow: 0px 0px 10px 0.5px rgb(0 0 0 / 75%);
    text-shadow: 2px 1px 1px var(--black);
    border-radius: 50px;
    margin: 10px 0;
    padding: 10px 20px;
  }
  #userhelptext {
    color: var(--white);
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
  }
}
