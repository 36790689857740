@font-face {
    font-family: ImportedComoRegular;
    src: url(../Como/Como.ttf);
}

@font-face {
    font-family: ImportedComoMedium;
    src: url(../Como/Como-Medium/Como-Medium.ttf);
}

@font-face {
    font-family: var(--font);
    src: url(../Como/Como-Bold/Como-Bold.ttf);
}


.commentdiv {
    background-color: var(--pinkDark);
    height: 100%;
    min-height: 100vh;
    padding: 62px 0px 0px 0px;
}

.cmline {
    height: 1px;
    background-color: var(--white);
    border-top: 1px solid var(--pinkDark);
    border-bottom: 1px solid var(--black);
}

.imgprofile {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid var(--black);
}

.comment {
    padding: 10px 10px;
    display: flex;
    align-items: center;
    color: var(--white);

}

#cmnt {
    padding: 0px 10px;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    height:50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#user{
    margin-top: 5px;
    text-transform: uppercase;
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
    line-height: 1rem;
    font-size: 1rem;
}
#msgs{
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: var(--font);
    text-shadow: 2px 1px 1px var(--black);
    font-size: 0.8rem;
    color: rgba(255,255,255, 0.75);
    margin-bottom: 5px;
    /* line-height: 1rem; */
}

.cmcount {
    height: 25px;
    width: 25px;
     border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--pitch);
    box-shadow: 0px 0px 3px 1px rgba(229, 229, 229, 0.75);

}
.add{
    position: fixed;
    bottom: 100px;
    right: 15px;
    height: 50px;
    width: 50px;
    background-color: var(--pink);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 3px rgb(255, 255, 255);
    color: var(--white);
    
}