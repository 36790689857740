@font-face {
  font-family: ImportedComoRegular;
  src: url(../../component/Como/Como.ttf);
}

@font-face {
  font-family: var(--font);
  src: url(../../component/Como/Como-Medium/Como-Medium.ttf);
}

@font-face {
  font-family: var(--font);
  src: url(../../component/Como/Como-Bold/Como-Bold.ttf);
}

#myprofileimg {
  height: 50px;
  width: 50px;
}

.mypvrline {
  height: 40px;
  width: 2px;
  background-color: var(--white);
  margin: 0 15px;
}
.MyprofileHeadingMain {
  position: fixed;
  z-index: 499;
  background-color: var(--black);
  top: 61.33px;
  padding: 10px 0 0 0;
  width: 100%;
}

.MyprofileHeadingdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px 0;
}

.mypline {
  height: 1px;
  background-color: var(--white);
  border-bottom: 1px solid var(--black);
  border-top: 1px solid var(--pinkDark);
}

#myprofileheading {
  font-family: var(--font);
  text-shadow: 2px 1px 1px var(--black);
  color: var(--yellow);
}

.myprofilemaindiv {
  background-color: var(--black);
  height: 100%;
  min-height: 82vh;
  padding: 134px 0 0 0;
}

#profilepicimg {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  margin-bottom: 20px;
}

#myprofilebtn {
  background-color: var(--themeCustomColor);
  border-radius: 50px;
  color: var(--white);
  font-family: var(--font);
  text-shadow: 2px 1px 1px var(--black);
  box-shadow: 0px 0px 3px 0.5px rgba(0, 0, 0, 0.75);
  text-transform: none;
}
#myprofilebtn:active {
  transform: translateY(4px);
}
.myprofile-uploadpic-div {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#myprofie-form-categaries {
  color: var(--white);
  font-family: var(--font);
  text-shadow: 2px 1px 1px var(--black);
}

.myprofile-form {
  display: flex;
  align-items: center;
  padding: 20px 20px 0 20px;
}
.myprofileinput {
  .MuiOutlinedInput-root {
    border-radius: 50px !important;
    box-shadow: 0px 0px 3px 0.5px rgba(0, 0, 0, 0.75);
    margin: 10px 0;
  }
  #myprofile-form-field {
    background-color: var(--themeCustomColor);
    color: var(--white);
    border-radius: 50px;
    font-family: var(--font);
  }
}
