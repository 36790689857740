.m_home{
    background-color: var(--pink);
    height: 100vh;
    position: fixed;
    padding: 60px 0 0 0;
    width: 100%;
    .m_home_tab{
        height: 90px;
            .tab-menu {
              display: flex;
              justify-content: center;
              width: 94%;
              padding:20px 3% 10px 3%; 

              .MuiTabs-scroller {
                position: relative;
                width: 90%;
                
                background-color: var(--pinkDark);
                border-radius: 15px;
                display: flex ;
                justify-content: center;
                box-shadow: 0px 0px 7px 0.1px black;
                .MuiTabs-flexContainer {
                  min-width: 100% !important;
                  width: 100%;
                  padding: 0px !important;
                  margin: 0px !important;
                  display: grid ;
                // justify-content: space-between;
                 grid-template-columns: auto auto;
                  .MuiTab-root {
                    width:100% !important;
                    margin: 0px 1px;
                    padding: 0px 8px !important;
                    color: white;
                    font-family: var(--font);
                    text-shadow: 0px 0px 0px;
                    text-transform: none;
                  }
                  button {
                    width: fit-content;
                    border: none !important;
                    padding: 0px !important;
                  }
                  .MuiTab-wrapper {
                    width: fit-content !important;
                  }
                  div {
                    img {
                      width: 25px;
                      aspect-ratio: 1/1;
                    }
                    span {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 10px;
                      color: var(--white);
                    }
                  }
                  .Mui-selected {
                    background-color: var(--white);
                    border-radius: 10px;
                    font-family: var(--font);
                    font-weight: 500;
                    color: var(--black);
                    div {
                      padding-left: 0px;
                      padding-right: 0px;
                    }
                  }
                }
              }
            }
          }
          .tabpanels {
            height: 140px !important;
           .m_ug_yellow{
            color: var(--yellow);
           }
           .b_update{
               display: grid;
               grid-template-columns: 30% 70%;
               align-items: center;
               margin: 10px 0px;
               .grp_bt{
                height: 30px;
                background-color: var(--pinkDark);
                width: fit-content;
                padding: 1px;
                box-shadow: 0px 0px 7px 1px var(--black);
               .yellow{
                background-color: var(--yellow) !important;
                border-radius: 5px;
               }
              
               .notyellow{
                background-color: var(--yellow) !important;
                border-radius: 5px;
        
        
               }}
              
              #myprofile-form-field {
                background-color: var(--pink);
                border: 2px solid var(--white);
                border-radius: 50px;
                font-family: var(--font);
                height: 25px !important;
              padding: 3px  10px !important;
            
            }
            
           }
           .up_b{
            height: 30px !important;
            margin: 10px 25% !important;
        }
        .a{
            padding: 0px !important;
            
        }
          }
}