
  
    .first{
      text-align: center;
      height:calc(100vh - 61.33px);
      width:100%;
      background-color: #110F0F;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 61.33px;

    }

    .success{
     
      color: red;
      display: flex;
      flex-direction: column;
    align-items: center;
    }
    .second{
      display: flex;
      justify-content: center;
    }
    .success2{
      background: green;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      /* text-align: center; */
      display: flex;
      justify-content: center;
      align-items: center;
    } 
    .success2_1{
      background: red;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      /* text-align: center; */
      display: flex;
      justify-content: center;
      align-items: center;
    } 
    .tick1{
      font-size: 10em;
      color:white
    }
  