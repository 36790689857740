.regisercss{
 
    .MuiOutlinedInput-root {
      margin-bottom: 15px;
    }
    
    .register-container {
      height: 100%;
      min-height: 100dvh;
      background-size: cover;
      background-position-x: center;
      position: sticky;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      img {
        width: 50%;
        margin-bottom: 25px;
      }
      .terms-and-condition {
        width: 90%;
        background: var(--gray);
        opacity: 1;
        height: 56px;
        border-radius: 28px;
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        a {
          color: var(--blueLight);
          
        }
      }
      .register-screen-button {
        margin-bottom: 15px;
        width: 90%;
        border-radius: 28px;
        height: 56px;
        background-color: var(--pink);
        text-transform: none;
        font-size: 20px;
      }
      .textfield {
        width: 90%;
      }
     
    }
    
  }
  @media only screen and (max-width:322px) {
    .regisercss{ #rgchek{
      font-size: 14px;
    }
  }}

  .register-container{
    display:flex;
    align-items: center;
    justify-content: center;
    img{
        margin-bottom:30%
    }
    .resend-otp-text{
        margin-bottom:10px;
    }
    .send-otp-page{
        .upper-text{
            color:var(--yellow);
        }
    }
    
    .upper-text1{
      text-align: center;
        margin-top:5%;
        margin-Bottom:10%;
    }

   
}
