.homecss {
  .homepage {
    padding: 70px 0px 20px 0px;
    width: 100%;
    background-color: var(--black);
    min-height: 100vh;
    hr {
      border-top: 1px solid var(--black);
      background-color: var(--white);
      height: 2px;
      border-bottom: 1px solid var(--black);
    }
  }
  .css-19kzrtu {
    padding: 0px;
  }
  .rec-arrow {
    display: none;
  }

  .story {
    width: 94%;
    padding: 0px 3%;
    // min-height: 9vh;
    display: flex;
    overflow: scroll;

    .data-image {
      border-radius: 50%;
      aspect-ratio: 1 / 1;
      height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 15%;
      width: 15%;
      padding: 5px 8px 3px 8px;
      .story-div {
        border: 1px solid var(--red);
        border-radius: 50%;
        aspect-ratio: 1/1;
        display: flex;
        box-shadow: 0px 0px 4px 1px var(--black);
        overflow: hidden;
        display: flex;
        align-items: center;
        img {
          width: 100px;
          height: 100px;
        }
      }

      .jZpChr {
        overflow: inherit;
      }
    }
  }

  .bannerSection {
    margin-top: 7px;
    display: flex;
    overflow: scroll;
    img {
      width: 94% !important;
      margin: 0 3%;
      border-radius: 15px;
    }
    .carousel-status {
      display: none;
    }
    .thumbs-wrapper {
      display: none;
    }
    .control-arrow {
      display: none;
    }
  }

  .shopSlider {
    margin-top: 10px;
    display: flex;
    overflow: scroll;
    img {
      width: 90%;
      aspect-ratio: 1/0.6;
      border-radius: 15px;
    }
  }

  .events {
    margin-top: 0px;

    img {
      max-width: -webkit-fill-available;
    }
  }

  .shop {
    display: flex;
    height: 125px;
    .shopcss {
      display: flex;
    }
    #lockicon {
      font-size: 45px !important;
      line-height: 1rem !important;
    }
    .lockicontext {
      font-size: 15px !important;
      line-height: 1rem !important;
    }
    .lock {
      background-image: var(--menubutton);
      margin-top: 20px;
      margin-left: 15px;
      width: 32% !important;
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--white);

      img {
        width: 50px;
      }
    }

    .rec-carousel {
      width: -webkit-fill-available;
      margin-left: 20px;
      margin-top: 15px;
      background-color: var(--pinkDark);
      padding: 0px 7px 0px 4px;
      display: flex;
      overflow: scroll;
      .rec-carousel-item {
        height: 120px !important;
        display: flex;
        align-items: center;

        img {
          height: 100px;
          width: 29%;
          border: 5px solid var(--pink);
          object-fit: cover;
          margin-left: 10px;
          border-radius: 15px;
        }
        .jZpChr {
          overflow: inherit;
        }
      }
    }
  }

  .actviepage {
    .icon {
      color: var(--gray) !important ;
      text-shadow: none;
    }
  }

  .actviepage:hover {
    .icon {
      color: var(--white) !important;
      text-shadow: none;
    }
  }
  .jWTnhb {
    overflow: unset;
  }

  .shoutOutButton {
    margin-top: 15px;
    width: calc(96% - 20px);

    button {
      background-image: var(--buttonColor);
      color: var(--white) !important;
      text-transform: none;
      margin-left: calc(10px + 2%);
      height: 50px;
      width: 100%;
      border-radius: 15px;
      font-size: 20px;
      text-shadow: 2px 2px 4px var(--black);
      font-family: var(--font);
      box-shadow: 0px 0px 7px 1px var(--black);
    }
    button:hover {
      background-color: var(--pitch);
    }
    button:active {
      background-color: var(--pitch);
      transform: translateY(4px);
    }
  }

  .typography-loginpage,
  .shoutOutButton,
  .latest-content,
  .video-content {
    font-family: var(--font) !important;
    text-shadow: 2px 2px 4px var(--black);
    .top-videos {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
  }

  .latest-content {
    #lokmsg {
      font-size: 10px;
      margin-top: 0px !important;
      padding-left: 0px;
    }
    p {
      margin-left: calc(10px + 2%);
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      text-align: start;
    }
    .rec-carousel {
      background-color: var(--pinkDark);
      height: 170px;
      display: flex;
      justify-content: start;
      align-items: center;

      .rec-carousel-item {
        display: flex;
        justify-content: center;
        height: 150px;
        overflow: scroll;
        max-width: inherit;
        .jZpChr {
          overflow: inherit;
          display: flex;
          justify-content: flex-start;

          .events {
            display: flex;
            max-width: 100%;
            overflow: scroll;
            height: 150px;
            align-items: center;
            .items:first-child {
              margin-left: 15px !important;
            }
            .items {
              display: flex !important;
              justify-content: center;
              height: calc(100% - 13px);
              margin-left: 7px;
              margin-right: 5px;
              border-radius: 15px;
              overflow: scroll;
              align-items: center;
              min-width: 26%;
              position: relative;
              // border: 5px solid var(--pink);
              box-shadow: 0px 0px 2px 1px var(--black);
              background-color: var(--black);
              .strip-text {
                color: var(--white);
                z-index: 2;
                position: absolute;
                top: 10px;
                font-size: 8px !important;
                display: flex;
                align-items: center;
                padding: 2px 5px;
                left: 0px;
                background-image: var(--lock);
                border-radius: 0 10px 10px 0;
                border-end-end-radius: 10x;
              }
              .video-play-icon {
                position: absolute;
                height: 100%;
                width: 90%;
                justify-content: center;
                display: flex;
                align-items: center;
              }
              .lockedImageIcon {
                position: absolute;
                z-index: 2;
                height: inherit;
                width: 90%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              img {
                height: 100%;
                max-width: 100%;
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  .video-content {
    p {
      text-align: start;
      padding-left: 15px;
    }
    .rec-carousel {
      background-color: var(--pinkDark);
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .rec-carousel-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        margin-left: calc(0px + 0%) !important;

        .rec-item-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .jZpChr {
          overflow: inherit;
          display: flex;
          height: inherit;
          justify-content: flex-start;
          align-items: center;
          .video-image {
            margin-left: 7px;
            margin-right: 5px;
            height: calc(100% - 7px);
            min-width: 55%;
            border: 2px solid var(--pink);
            box-shadow: 0px 0px 2px 1px var(--black);
          }
          .events {
            display: flex;
            max-width: 100%;
            overflow: scroll;
            height: 165px;
            width: 100vw;
            align-items: center !important;
            .items {
              display: flex !important;
              height: calc(100% - 7px);
              align-items: center;
              min-width: 60%;
              position: relative;
              overflow: hidden;
              margin-left: 7px;
              margin-right: 5px;
              height: calc(100% - 13px);
              // border: 5px solid var(--pink);
              border-radius: 15px;
              box-shadow: 0px 0px 2px 1px var(--black);
              .strip-text {
                color: var(--white);
                z-index: 2;
                position: absolute;
                top: 10px;
                font-size: 8px !important;
                display: flex;
                align-items: center;
                padding: 2px 16px;
                background-image: var(--lock);
                border-radius: 0 10px 10px 0;
                border-end-end-radius: 10x;
              }
              .video-play-icon {
                position: absolute;
                height: 100%;
                width: 90%;
                justify-content: center;
                display: flex;
                align-items: center;
                z-index: 2;
              }
              .lockedImageIcon {
                position: absolute;
                z-index: 2;
                height: inherit;
                width: 90%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }

  .bottom-menu {
    .MuiTabs-scroller {
      position: fixed;
      bottom: 0px;
      z-index: 3;
      height: 60px;

      display: flex !important;
      justify-content: center;
      .MuiTabs-flexContainer {
        max-height: 60px !important;
        min-width: 100% !important;
        display: flex;
        justify-content: space-between;
        background-color: var(--black);
        padding: 2px;
        margin: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        .MuiTab-root {
          min-width: auto !important;
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
        button {
          width: 20%;
          border: none !important;
          padding: 0px !important;
        }
        .MuiTab-wrapper {
          width: fit-content !important;
        }
        div {
          img {
            width: 25px;
            aspect-ratio: 1/1;
          }
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            color: var(--white);
          }
        }
        .Mui-selected {
          // background-color: var(--pinkTransperent);
          div {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }

  .MuiTabs-root {
    border: none;
  }

  .rec-carousel-wrapper {
    .rec-pagination {
      position: absolute;
      z-index: 3;
      width: calc(96% - 20px);
      button {
        background-color: var(--white);
        box-shadow: none !important;
      }
    }
  }
}

.video-text {
  font-size: 10px !important;
}

#playicon {
  width: 30px;
  height: 30px;
  border: none !important;
  box-shadow: none !important;
}

.page-label {
  font-size: 1rem;
}

.items:first-child {
  margin-left: 15px !important;
}
