.setbox {
  position: fixed;
  background-color: var(--black);
  height: 100%;
  min-height: 75vh;
  padding: 25vh 20px;
  padding-bottom: 0vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  #setbtn {
    background-color: var(--themeCustomColor);
    color: var(--white);
    width: 90%;
    margin: 10px 10px 20px 10px;
    font-family: var(--font);
    border: none;
    border-radius: 10px;
    text-transform: none;
    box-shadow: 0px 2px 10px 0.5px var(--black);
    padding: 10px;
  }
  #deleteac {
    background-color: var(--themeCustomColor);
    width: 90%;
    margin: 10px 10px 20px 10px;
    color: var(--white);
    text-transform: inherit;
    font-family: var(--font);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 2px 10px 0.5px var(--black);
    padding: 10px;
  }
  #setbtn:active #deleteac:active {
    transform: translateY(4px);
  }
  #settext {
    color: var(--yellow);
    font-family: var(--font);
    text-align: center;
    margin: 10px 0px 0 0;
    width: 100%;
  }
}

#modal-button {
  position: fixed;
  bottom: 0px;
  min-width: 100vw;
}
