.giftmaindiv {
  padding: 61.33px 0 0 0;
  height: 100%;
  background-color: var(--pink);
  .volumebox {
    height: 40px;
    width: 40px;
    background-color: var(--pink);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 80px;
    right: 20px;
    box-shadow: 0px 0px 7px 0.1px var(--black);
  }
  .giftmevideo {
    width: 100vw;
  }
  .giftmehead {
    background-color: var(--black);
    .giftmeheading {
      color: var(--yellow);
      font-family: var(--font);
      text-shadow: 2px 1px 1px var(--black);
      text-align: center;
      padding: 10px 0;
    }
    .giftmeheadingtext {
      font-family: var(--font);
      text-shadow: 2px 1px 1px var(--black);
      text-align: center;
      padding: 10px 5%;
      line-height: 1rem;
    }
  }
  .giftmetextfield {
    background-color: var(--black);
    padding: 5% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .myprofile-form-field {
      background-color: var(--pink);
      color: var(--white);
      border-radius: 50px;
    }
    #pink-background-field {
      background-color: var(--pink);
      border-radius: 26px;
      font-family: var(--font);
      padding-top: 15px !important;
      padding-bottom: 15px;
    }
    .giftmeheadingtext2 {
      font-family: var(--font);
      text-shadow: 2px 1px 1px var(--black);
      text-align: center;
      padding: 10px 0;
      line-height: 1rem;
    }
    .giftmebtn {
      background-color: var(--pink);
      border-radius: 50px;
      font-family: var(--font);
      margin: 10px 0;
      text-transform: capitalize;
      font-size: large;
      padding: 2%;
      box-shadow: 0px 0px 7px 0.5px var(--black);
    }
    .giftmebtn:active {
      transform: translateY(4px);
    }
    .giftmebtn2 {
      border-radius: 50px;
      font-family: var(--font);
      text-shadow: 2px 1px 1px var(--black);
      text-transform: none;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 50px !important;
    box-shadow: 0px 0px 7px 0.5px var(--black);
    margin: 10px 0;
  }
}
