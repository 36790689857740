@font-face {
    font-family: ImportedComoRegular;
    src: url(../Como/Como.ttf);
}

@font-face {
    font-family: ImportedComoMedium;
    src: url(../Como/Como-Medium/Como-Medium.ttf);
}

@font-face {
    font-family: var(--font);
    src: url(../Como/Como-Bold/Como-Bold.ttf);
}
.head{
    position: fixed;
    background-color: var(--pink);
    width: 100%;

}
.helpbox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    

}
#heading{
    padding: 10px;
   
}
#st{
    color: var(--yellow);
    font-family: var(--font);
    text-align: center;
}
.backbtn {
    position: fixed;
    left: 15px;
    top: 10px;
    background-color: var(--pinkDark);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
}

.adminLogo {
    height: 40px;

}
.line {
    height: 1px;
    background-color: var(--white);
    border-top: 1px solid var(--pinkDark);
    border-bottom: 1px solid var(--black);
}
.cline {
    height: 10px;
    background-color: var(--pink);
    border-top: 1px solid var(--white);
    
}
.helpmain{
    background-color: var(--pink);
    height: 100%;
    min-height: 100vh;
    padding: 135px 10px;

}
.helpdiv{
    padding: 10px;
    height: auto;
    text-align: center;
  border-radius: 10px;
  margin-bottom: 10px;
}

.cline{
    height: 0.01px;
    background-color: var(--white);
    border-bottom: 1px solid var(--black);
    margin: 10px 0px;
}
#helptext{
    color: var(--white);
    line-height: 1rem;
    font-family: var(--font);
    text-shadow: 2px 2px 1px  var(--black);
    padding: 5px 0px;
}
#helptext1{
    color: rgb(201, 201, 201);
    line-height: 1rem;
    font-family: var(--font);
    text-shadow: 2px 2px 1px  var(--black);
    padding: 5px 0px;
}
#helpbtn{
    border-radius: 40px;
    padding: 10px 20px;
    background-color: var(--pink);
    border:2px solid var(--white) ;
    font-family: var(--font);
    font-size: 16px;
    text-transform: capitalize;
    position: fixed;
    bottom: 50px;
    right: 15px;
}
#helpbtn:active , .backbtn:active{
    transform: translateY(4px);
}